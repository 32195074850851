import { usePendingOperation } from "core/utils";
import { useState } from "react";
import securityService from "./securityService";
import { Form, SubmitButton, TextField } from "core/forms";
import { FeedbackMessage } from "core/content";
import { tx } from "core/intl";

interface LoginRequiredCodeFormProps {
  onLoginActionRequired: () => any;
  onGoBackToLoginForm: () => any;
}

interface Model {
  code: string;
}

export function LoginRequiredCodeForm(props: LoginRequiredCodeFormProps) {
  const [model, setModel] = useState<Model>({ code: "" });
  const op = usePendingOperation();

  const accept = () => {
    if (op.syncPending) return;

    op.handle(
      securityService.loginWithCode(model.code).then(res => {
        if (res.requiredActions) {
          props.onLoginActionRequired();
        }
      })
    );
  };

  return (
    <Form rootComponent="form" model={model} onChangeDispatch={setModel} onSubmit={accept} disabled={op.state.pending}>
      <FeedbackMessage className="mb-8">Wymagane jest podanie kodu jednorazowego:</FeedbackMessage>
      <TextField name="code" placeholder="Wpisz 6-cyfrowy kod" autoFocus />
      {op.state.error && (
        <FeedbackMessage kind="error" className="mt-8">
          {op.state.errorMessage || tx("Błąd operacji")}
        </FeedbackMessage>
      )}
      <div className="text-right">
        <SubmitButton cta big disabled={op.state.pending || !model.code}>
          {op.state.pending ? tx("Logowanie...") : tx("Zaloguj")}
        </SubmitButton>
      </div>
    </Form>
  );
}
