import { Button } from "../forms";
import { FeedbackMessage, Text } from "../content";
import { AuthActionType } from "apinet/models";
import { useDataLoader } from "core/utils";
import { authService } from "apinet";
import { LoginRequiredPasswordChangeForm } from "./LoginRequiredPasswordChangeForm";
import { LoginRequiredCodeForm } from "./LoginRequiredCodeForm";

interface LoginRequiredActionFormProps {
  onGoBackToLoginForm: () => any;
}

export function LoginRequiredActionForm(props: LoginRequiredActionFormProps) {
  const [currentAction, loading, refresh] = useDataLoader(() => authService.getCurrentRequiredAction());

  if (loading.state.pending) {
    return <Text>Proszę czekać...</Text>;
  }

  if (loading.state.error) {
    return <Text danger>Błąd operacji</Text>;
  }

  if (!currentAction) {
    return null;
  }

  if (currentAction.invalidToken) {
    return (
      <>
        <FeedbackMessage>Nieprawidłowa operacja lub czas operacji minął</FeedbackMessage>
        <Button className="mt-16" onClick={props.onGoBackToLoginForm}>
          Wróć do logowania
        </Button>
      </>
    );
  }

  if (currentAction.actionType === AuthActionType.RequiredPasswordChange) {
    return (
      <LoginRequiredPasswordChangeForm
        onGoBackToLoginForm={props.onGoBackToLoginForm}
        onLoginActionRequired={() => refresh()}
      />
    );
  }

  if (currentAction.actionType === AuthActionType.RequiredTwoFactorCode) {
    return (
      <LoginRequiredCodeForm onGoBackToLoginForm={props.onGoBackToLoginForm} onLoginActionRequired={() => refresh()} />
    );
  }

  return <FeedbackMessage kind="warning">Nierozpoznana operacja</FeedbackMessage>;
}
