// File generated automatically, do not modify

import axios, { AxiosResponse } from 'axios';
import { UserProfile, UserUpdateContactCommand, UserChangePasswordCommand, VerifyPasswordOfCurrentUserCommand, VerifyPasswordOfCurrentUserResult, UserSetupTotpCommand, UserTotp, UserDevice, UserTrustThisDeviceCommand } from './models';

const staticRequestHeaders = {
  'x-api-v2': '1'
}

const paths = {
  getUserProfile: 'api/CurrentUser/GetUserProfile',
  updateContactData: 'api/CurrentUser/UpdateContactData',
  changePassword: 'api/CurrentUser/ChangePassword',
  verifyCurrentUserPassword: 'api/CurrentUser/VerifyCurrentUserPassword',
  setUpTotp: 'api/CurrentUser/SetUpTotp',
  confirmTotp: 'api/CurrentUser/ConfirmTotp',
  disableTotp: 'api/CurrentUser/DisableTotp',
  getCurrentDevice: 'api/CurrentUser/GetCurrentDevice',
  trustCurrentDevice: 'api/CurrentUser/TrustCurrentDevice',
};

function getUserProfile(): Promise<UserProfile> {
  return axios.request<UserProfile>({
    url: paths.getUserProfile,
    method: 'get',
    headers: staticRequestHeaders,
  }).then(res => res.data);
}

function updateContactData(data: UserUpdateContactCommand): Promise<AxiosResponse<any>> {
  return axios.request<any>({
    url: paths.updateContactData,
    method: 'post',
    headers: staticRequestHeaders,
    data,
  });
}

function changePassword(data: UserChangePasswordCommand): Promise<AxiosResponse<any>> {
  return axios.request<any>({
    url: paths.changePassword,
    method: 'post',
    headers: staticRequestHeaders,
    data,
  });
}

function verifyCurrentUserPassword(data: VerifyPasswordOfCurrentUserCommand): Promise<VerifyPasswordOfCurrentUserResult> {
  return axios.request<VerifyPasswordOfCurrentUserResult>({
    url: paths.verifyCurrentUserPassword,
    method: 'post',
    headers: staticRequestHeaders,
    data,
  }).then(res => res.data);
}

function setUpTotp(data: UserSetupTotpCommand): Promise<UserTotp> {
  return axios.request<UserTotp>({
    url: paths.setUpTotp,
    method: 'post',
    headers: staticRequestHeaders,
    data,
  }).then(res => res.data);
}

interface ConfirmTotpParams {
  code?: string;
}

function confirmTotp(params: ConfirmTotpParams): Promise<AxiosResponse<any>> {
  return axios.request<any>({
    url: paths.confirmTotp,
    method: 'post',
    params,
    headers: staticRequestHeaders,
  });
}

function disableTotp(data: UserSetupTotpCommand): Promise<AxiosResponse<any>> {
  return axios.request<any>({
    url: paths.disableTotp,
    method: 'post',
    headers: staticRequestHeaders,
    data,
  });
}

function getCurrentDevice(): Promise<UserDevice> {
  return axios.request<UserDevice>({
    url: paths.getCurrentDevice,
    method: 'get',
    headers: staticRequestHeaders,
  }).then(res => res.data);
}

function trustCurrentDevice(data: UserTrustThisDeviceCommand): Promise<AxiosResponse<any>> {
  return axios.request<any>({
    url: paths.trustCurrentDevice,
    method: 'post',
    headers: staticRequestHeaders,
    data,
  });
}

export const currentUserService = {
  paths,
  getUserProfile,
  updateContactData,
  changePassword,
  verifyCurrentUserPassword,
  setUpTotp,
  confirmTotp,
  disableTotp,
  getCurrentDevice,
  trustCurrentDevice,
};
