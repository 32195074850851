import { useState } from "react";
import { ClickableText } from "core/forms";
import { PasswordRecoveryForm } from "./PasswordRecoveryForm";
import "./LoginPageContent.scss";
import { LoginPageLanguageSelector } from "./LoginPageLanguageSelector";
import { LogoSvgImg } from "core/utils/LogoSvgImg";
import { LoginProcessHandler } from "./LoginProcessHandler";

export function LoginPageRightContent() {
  const [login, setLogin] = useState("");
  const [recoveryMode, setRecoveryMode] = useState(false);

  const startRecovery = () => {
    setLogin("");
    setRecoveryMode(true);
  };

  const recoveryCompleted = (login: string) => {
    setLogin(login);
    setRecoveryMode(false);
  };

  const content = recoveryMode ? (
    <PasswordRecoveryForm onCompleted={recoveryCompleted} onCancel={() => setRecoveryMode(false)} login={login} />
  ) : (
    <LoginProcessHandler login={login} />
  );

  const logoHeight = recoveryMode ? "86px" : "96px";

  return (
    <div className="login-page-content">
      <div className="logo-container" style={{ height: logoHeight }}>
        <LogoSvgImg />
      </div>
      {content}
      {!recoveryMode && (
        <>
          <div className="footer">
            <ClickableText overline onClick={startRecovery}>
              Nie pamiętasz hasła?
            </ClickableText>
          </div>
          <div className="footer language-selector">
            <LoginPageLanguageSelector />
          </div>
        </>
      )}
    </div>
  );
}
